import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";

const CustomCloseButton = ({ open, setOpen, onClickEvent }: { open?: boolean, setOpen?: React.Dispatch<React.SetStateAction<boolean>>, onClickEvent?: () => void }) => {
  return (
    <button
      type="button"
      className="absolute top-2 right-2 p-1 rounded-full border group"
      onClick={() => {
        if (onClickEvent) onClickEvent();
        if (setOpen && open) setOpen(!open);
      }}
    >
      <XMarkIcon className="w-6 h-6 text-gray-400 group-hover:text-gray-600" />
    </button>
  );
}

export default CustomCloseButton;