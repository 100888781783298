import { Controller } from "@hotwired/stimulus";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

export default class extends Controller {
  static targets = ["playlists", "audioFiles", "selectedSound", "duration"];

  connect() {
    this.renderPlaylists();
    setTimeout(() => {
      const firstPlaylistButton = this.playlistsTarget.querySelector("p");
      if (firstPlaylistButton) {
        firstPlaylistButton.click();
      }
    }, 0); 
  }

  // Variable pour stocker la piste et le bouton actuellement en lecture
  currentPlayingWaveSurfer = null;
  currentPlayButton = null;

  async fetchAudioFiles(playlistId, clickedButton) {
    const apiKey = "IQ7FP9SY8l2txiHp30ma7wFmgfQDLeenaMiYFYvCvxBG18psCl2OGimCtd4MfbLf";

    try {
      const response = await fetch(
        `https://api.soundstripe.com/v1/playlists/${playlistId}?include=songs.audio_files&page[size]=15&page[number]=1`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch audio files");
      }

      const data = await response.json();
      const songsData = data.included.filter((item) => item.type === "songs");
      const audioFilesData = data.included.filter(
        (item) => item.type === "audio_files"
      );
      const audioFilesWithTitles = audioFilesData.map((audioFile) => {
        const song = songsData.find(
          (song) => song.id === audioFile.attributes.song_id
        );
        return {
          ...audioFile,
          songTitle: song ? song.attributes.title : "Unknown Title",
          url: audioFile.attributes.versions.mp3,
        };
      });

      this.displayAudioFiles(audioFilesWithTitles);

      // Gestion de la sélection visuelle des playlists
      this.updatePlaylistStyles(clickedButton);

    } catch (error) {
      console.error("Error fetching audio files:", error);
    }
  }

  displayAudioFiles(audioFiles) {
    const audioFilesContainer = this.audioFilesTarget;
    audioFilesContainer.innerHTML = ""; // clear container before rendering
  
    let selectedAudioItem = null; 
  
    audioFiles.forEach((audioFile) => {
      const audioItem = document.createElement("div");
      audioItem.classList.add("flex", "justify-between", "items-center", "border-b", "border-gray-200", "cursor-pointer","p-3", "hover:bg-brand_lighter/30");
  
      const infoContainer = document.createElement("div");
      infoContainer.classList.add("flex", "gap-3", "items-center");
    
      const title = document.createElement("p");
      title.classList.add("text-gray-900", "truncate", "w-40");
      title.innerText = audioFile.songTitle;
  
      infoContainer.appendChild(title);
  
      const waveformContainer = document.createElement("div");
      waveformContainer.style.width = "500px";
      waveformContainer.id = `waveform-${audioFile.id}`;
  
      const plugins = RegionsPlugin.create({});
      const ws = WaveSurfer.create({
        container: waveformContainer,
        waveColor: 'rgb(67,56,202)',
        progressColor: 'rgb(67,56,202)',
        height: 80,
        backend: 'MediaElement',
        url: audioFile.url,
        pixelRatio: 0,
        plugins: [plugins],
      });
  
      let currentRegion = null;
      let isPlaying = false;
  
      const controlsContainer = document.createElement("div");
      controlsContainer.classList.add("flex", "gap-4");
  
      const playFullAudioBtn = document.createElement("div");
      playFullAudioBtn.classList.add("bg-brand_main", "text-white", "py-2", "px-2", "rounded", "text-sm");
      playFullAudioBtn.innerText = "Play Audio";
      playFullAudioBtn.onclick = () => {
        // Si une autre piste est en cours de lecture, la mettre en pause et réinitialiser son bouton
        if (this.currentPlayingWaveSurfer && this.currentPlayingWaveSurfer !== ws) {
          this.currentPlayingWaveSurfer.pause();
          this.currentPlayingWaveSurfer.seekTo(0); // Réinitialise la piste en cours
          this.currentPlayButton.innerText = "Play Audio"; // Réinitialise le texte de l'ancien bouton
        }

        // Bascule la lecture de la piste actuelle
        ws.playPause();
        playFullAudioBtn.innerText = ws.isPlaying() ? "Pause Audio" : "Play Audio";

        // Met à jour la piste et le bouton en cours de lecture
        this.currentPlayingWaveSurfer = ws.isPlaying() ? ws : null;
        this.currentPlayButton = ws.isPlaying() ? playFullAudioBtn : null;
      };
      controlsContainer.appendChild(playFullAudioBtn);
  
      audioItem.appendChild(infoContainer);
      audioItem.appendChild(waveformContainer);
      audioItem.appendChild(controlsContainer);
      audioFilesContainer.appendChild(audioItem);      
  
      ws.on("ready", () => {
        currentRegion = ws.plugins[0].addRegion({
          start: 0,
          color: 'black',      
        });
  
        currentRegion.on("update-end", (region) => {
          if (selectedAudioItem) {
            selectedAudioItem.classList.remove("bg-brand_lighter/30");
          }
  
          audioItem.classList.add("bg-brand_lighter/30");
          selectedAudioItem = audioItem; 
  
          document.getElementById("audio-start").value = currentRegion.start;
          document.getElementById("audio-end").value = ws.getDuration();
          document.getElementById("audio-url").value = audioFile.url;

          document.getElementById("song-selected").value = "true";
          
          ws.seekTo(currentRegion.start / ws.getDuration());
          this.render_selected_sound_info(currentRegion.start, ws.getDuration(), audioFile.songTitle);
          this.render_duration_info(currentRegion.start, ws.getDuration());
        }); 
      });
  
      ws.on("audioprocess", (time) => {
        if (currentRegion && time > ws.getDuration()) {
          ws.pause();
          ws.seekTo(currentRegion.start / ws.getDuration());
          isPlaying = false;
        }
      });
    });
  }

  render_selected_sound_info(start, end, sound_name) {
    const formattedStart = this.formatTime(start);
    const formattedEnd = this.formatTime(end);
    
    this.selectedSoundTarget.innerText = `${sound_name} - Début : ${formattedStart} - Fin : ${formattedEnd}`;
  }

  render_duration_info(start, end) {
    const duration = end - start;
    const formattedDuration = this.formatTime(duration);
    this.durationTarget.innerText = `Durée : ${formattedDuration}`;
  }
  
  formatTime(timeInSeconds) {
    if (timeInSeconds < 30) {
      return `${Math.round(timeInSeconds)} sec`;
    } else {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.round(timeInSeconds % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} min`;
    }
  }

  renderPlaylists() {
    const playlistMap = {
      "The Vocal Vault": "9821",
      "Epic Score": "97299",
      "Adrenaline Junkie": "97298",
      Quirky: "9",
      "Young and Free": "868",
      "Rock Rock Rock": "8006",
      "Holiday Vocals": "76589",
      "Fall Vibes": "75",
      Chill: "7457",
      "Indie Radio": "73",
    };

    const playlistsContainer = this.playlistsTarget;

    playlistsContainer.innerHTML = ""; // Clear container

    Object.keys(playlistMap).forEach(playlist => {
      const playlistId = playlistMap[playlist];

      const button = document.createElement("p");
      button.classList.add("text-xs", "border", "border-brand_main", "rounded-full", "px-2.5", "py-1", "cursor-pointer", "text-brand_main", "bg-white");

      button.innerText = playlist;
      button.addEventListener("click", () => {
        this.fetchAudioFiles(playlistId, button);
      });

      playlistsContainer.appendChild(button);
    });
  }

  updatePlaylistStyles(clickedButton) {
    const allButtons = this.playlistsTarget.querySelectorAll("p");

    allButtons.forEach(button => {
      if (button === clickedButton) {
        button.classList.add("bg-brand_main", "text-white");
        button.classList.remove("bg-white", "text-brand_main");
      } else {
        button.classList.add("bg-white", "text-brand_main");
        button.classList.remove("bg-brand_main", "text-white");
      }
    });
  }
}