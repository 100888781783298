import React, {
  Fragment,
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  useRef,
  ReactNode
} from "react";
import update from "immutability-helper";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { HTML5Backend } from "react-dnd-html5-backend";
import { scale, thumbnail } from "@cloudinary/url-gen/actions/resize";
import {
  CloudinaryFile,
  // PersistedCloudinaryFile,
  // NewCloudinaryFile,
  // isPersisted,
} from "../types/cloudinary_custom_types";
import useCloudinaryUpload from "./useCloudinaryUpload";
import { ContentMedium, isPersisted, MediaHolder } from "../types";
import { FormattedMessage } from 'react-intl';


export const ItemTypes = {
  MEDIA_CARD: "media_card",
};


export default function ContentMediaUploader(
  {
    media,
    setMedia,
    disabled,
    buttonText,
    innerInfos = "PNG, JPG jusqu'à 10MB",
    inputProps,
    error,
  }: {
    media: Array<MediaHolder>;
    setMedia: React.Dispatch<React.SetStateAction<Array<MediaHolder>>>;
    disabled?: boolean;
    inputProps?: { [key: string]: any };
    buttonText?: string | ReactNode;
    innerInfos?: string | ReactNode;
    error?: boolean | string;
  }) {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dcmekntwa",
    },
  });

  const removePersistedMedia = (file: ContentMedium & { id: number }) => {
    setMedia(
      media.map((medium) =>
        medium.content_medium.serialized_file.identifier === file.serialized_file.identifier
          ? { ...medium, destroy: true }
          : medium
      )
    );
  };

  const removeMedia = async (id: string) => {    
    const fileToRemove = media.find((m) => m.content_medium.serialized_file.public_id == id)?.content_medium;    

    if (fileToRemove) {
      if (isPersisted(fileToRemove)) {
        // le media est persisté, on laissera le back se charger de sa destruction
        removePersistedMedia(fileToRemove);
      } else if (fileToRemove.serialized_file.delete_token) {
        // Le media est nouveau, on le liquide immédiatement chez cloudinary et le fait disparaître
        const formData = new FormData();
        formData.append("token", fileToRemove.serialized_file.delete_token);
        const res = await fetch(
          `https://api.cloudinary.com/v1_1/${cld.getConfig().cloud?.cloudName
          }/delete_by_token`,
          { method: "post", body: formData }
        );
        if (res.status == 200) {
          setMedia(
            media.filter(
              (m) =>
                m.content_medium.serialized_file.public_id !==
                fileToRemove.serialized_file.public_id
            )
          );
        }
      }
    }
  };

  const addMedia = (file: CloudinaryFile) => {
    // Pourquoi seul un media est retenu ???

    setMedia([...media, { content_medium: { serialized_file: file } }]);
  };

  const { uploadFile, statusText } = useCloudinaryUpload({ cld, addMedia });

  const handleFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      for (var i = 0; i < files.length; i++) {
        uploadFile(files[i]); // call the function to upload the file
      }
    }
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setMedia((prevMedia: MediaHolder[]) =>
      update(prevMedia, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevMedia[dragIndex] as MediaHolder],
        ],
      })
    );

  }, []);

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2 media-input-field">
      <DndProvider backend={HTML5Backend}>
        <div
          className={`flex justify-center px-6 pt-5 pb-6 border-2 ${error ? "border-red-300" : "border-gray-300"
            } border-dashed rounded-md`}
        >
          <div className="space-y-1 text-center">
            {!disabled && (
              <>
                <svg
                  className="w-12 h-12 mx-auto text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex justify-center text-sm text-gray-600">
                  <label className="clickable-text">
                    {buttonText ? buttonText : <FormattedMessage id="content_media_uploader.btn_text" defaultMessage="Choisir un fichier ici" />}
                    <input
                      type="file"
                      {...inputProps}
                      style={{ display: "none" }}
                      onChange={handleFiles}
                      multiple={true}
                    />
                  </label>
                </div>
                {statusText && (
                  <p className="text-xs text-gray-500 status">{statusText}</p>
                )}
                <p className="text-xs text-gray-500 ">
                  {innerInfos}
                </p>
              </>
            )}

            <div className="flex flex-wrap justify-center space-x-2 preview">
              {media
                .filter((medium) => !(isPersisted(medium) && medium?.destroy))
                .map((medium, i) => (
                  <MediaThumbnail
                    index={i}
                    cld={cld}
                    medium={medium.content_medium}
                    handleRemove={removeMedia}
                    //readonly={Boolean(disabled)}
                    moveCard={moveCard}
                  />
                ))}
            </div>
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type ThumbnailProps = {
  cld: Cloudinary;
  medium: ContentMedium;
  handleRemove: (id: string) => void;
  readonly?: boolean;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
};
function MediaThumbnail({
  cld,
  medium,
  handleRemove,
  readonly,
  index,
  moveCard,
}: ThumbnailProps): any {
  const { resource_type, public_id, format } = medium.serialized_file;
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.MEDIA_CARD,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => !readonly
  }));

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.MEDIA_CARD,
    collect(monitor: any) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: any) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      if (item.index !== undefined) {
        moveCard(dragIndex, hoverIndex);
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      className={`relative rounded-sm shadow-md p-1 `}
      id={medium.serialized_file.public_id}
      ref={ref}
      data-handler-id={handlerId}
    >
      {!readonly && (
        <button
          type="button"
          onClick={() => {
            handleRemove(medium.serialized_file.public_id);
          }}
          className="absolute w-3 h-3 text-brand_main transition ease-in-out bg-white rounded-full top-2 right-2 hover:text-white hover:bg-brand_darker hover:scale-125"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
      <AdvancedImage
        className="object-cover w-24 h-24 m-1/2"
        cldImg={cld
          .image(public_id)
          .setAssetType(resource_type)
          .format(resource_type === "video" ? "jpg" : format)
          .resize(thumbnail().width(320))}
      />
    </div>
  );
}
